import * as React from 'react';
import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

import { firebaseConfig } from '../firebase.config';

import AuthLayout, { AuthLayoutStyle } from '../components/auth-layout';
import Meta from '../components/meta';
import { SignInPageTitle, SignIn, SignInCreateAccount, } from '../localisation';
import { Url } from '../url';

import * as classes from './sign-in.module.css';

const uiConfig = {
  signInSuccessUrl: '/app/',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    'apple.com',
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: '/terms',
  // Privacy policy url/callback.
  privacyPolicyUrl: '/privacy',
};

function SignupPage() {
  const mixpanel = useMixpanel();

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);

    const ui = new firebaseui.auth.AuthUI(firebase.auth());

    ui.start(
      '#firebase-ui',
      {
        ...uiConfig,
        callbacks: {
          signInFailure(error: firebaseui.auth.AuthUIError): Promise<void> | void {
            mixpanel.track('sign_in_error', { code: error.code, message: error.message });
          },
          uiShown() {
            mixpanel.track('sign_in_ui_shown');
          }
        }
      }
    );

    return () => {
      ui.delete();
    };
  }, []);

  return (
    <AuthLayout style={AuthLayoutStyle.SignIn}>
      <Meta
        title={SignInPageTitle}
        url={Url.GetStarted}
      />

      <h1 className={classes.title}>
        {SignIn}
      </h1>

      <div className={classes.formWrapper}>
        <div id="firebase-ui" />
      </div>

      <div className={classes.signUp}>
        {SignInCreateAccount}
      </div>
    </AuthLayout>
  );
}

export default SignupPage;
