import * as React from 'react';
import classNames from 'classnames';

import AuthLayoutPicture from '../images/auth-layout.svg';
import AuthLogoSignIn from '../images/auth-logo-sign-in.svg';
import AuthLogoSignUp from '../images/auth-logo-sign-up.svg';
import MobileLogo from '../images/logo.svg';

import * as classes from './auth-layout.module.css';

export enum AuthLayoutStyle {
  SignIn,
  SignUp,
}

type AuthLayoutProps = {
  style: AuthLayoutStyle,
  children: React.ReactNode,
};

function AuthLayout(props: AuthLayoutProps) {
  const { style, children } = props;

  let styleClassName = '';
  let logoPicture = AuthLogoSignIn;

  switch (style) {
    case AuthLayoutStyle.SignIn:
      styleClassName = classes.signIn;
      break;
    case AuthLayoutStyle.SignUp:
      styleClassName = classes.signUp;
      logoPicture = AuthLogoSignUp;
      break;
    default:
      throw new Error('Unknown AuthLayoutStyle');
  }

  return (
    <div className={classNames({
      [classes.layout]: true,
      [styleClassName]: true,
    })}>
      <div className={classes.leftColumn}>
        <div className={classes.logo}>
          <a href="/"><img src={logoPicture} alt="emery.to" /></a>
        </div>

        <img src={AuthLayoutPicture} alt="Illustration" className={classes.picture} />
      </div>
      <div className={classes.rightColumn}>
        <div className={classes.mobileLogo}>
          <a href="/"><img src={MobileLogo} alt="emery.to" className={classes.mobileLogoPicture} /></a>
        </div>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
